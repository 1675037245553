// Link errors generated by back-end protobuf file in ~/api/errcode.proto to human-readable messages

export const apiErrorsCode = new Map([
  // create organization
  ["ErrReservedName(#4022)", "this name is unauthorized"],
  ["ErrCheckOrganizationUniqueName(#4023)", "an organization with this name already exists"],

  // invite user to organization
  ["ErrNotOrganizationOwner(#4094)", "you are not the owner of this organization"],
  ["ErrOrganizationUserAlreadyMember(#4095)", "this user is already a member of this organization"],
  ["ErrAlreadyInvitedInOrganization(#4096)", "this user is already invited in this organization"],

  // accept organization invitation
  ["ErrOrganizationDoesNotExist(#4093)", "this organization does not exist"],
  ["ErrOrganizationUserAlreadyMember(#4097)", "this user is already a member of this organization"],

  // create team
  ["ErrGetSeason(#4019)", "this season does not exist"],
  ["ErrSeasonDenied(#4020)", "you are not allowed to create a team in this season"],
  ["ErrAlreadyHasTeamForSeason(#4021)", "you already have a team in this season"],
  ["ErrUserNotInOrganization(#4029)", "you are not a member of this organization"],
  ["ErrOrganizationAlreadyHasTeamForSeason(#4025)", "this organization already has a team in this season"],
  ["ErrCannotCreateTeamForGlobalOrganization(#4028)", "you cannot create a team for a personal global organization"],
  ["ErrSeasonIsNotStarted(#4105)", "this season is not started yet"],
  ["ErrSeasonIsEnded(#4106)", "this season is ended"],
  ["ErrSeasonLimitTotalTeamsReached(#4107)", "the total number of teams for this season has been reached"],
  ["ErrSeasonEmailDomainNotAllowed(#4108)", "the email domain is not allowed for this season"],

  // invite user to team
  ["ErrTeamDoesNotExist(#4086)", "this team does not exist"],
  ["ErrNotTeamOwner(#4087)", "you are not the owner of this team"],
  ["ErrAlreadyInvitedInTeam(#4088)", "this user is already invited in this team"],
  ["ErrSeasonTeamLimitIsFull(#4109)", "the maximum number of members for this team has been reached"],

  // buy challenge
  ["ErrNotEnoughCash(#4071)", "you don't have enough cash to buy this challenge"],

]);
